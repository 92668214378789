<template>
    <div id="displayOptions" class="mr-2">
        <label class="pr-2">{{ translation.translate("frequentlyRepeated", "pokazatPo") }}:</label>
        <b-dropdown id="ddown2"
                    right
                    :text="limit"
                    variant="outline-dark"
                    size="xs">
            <b-dropdown-item v-for="optLimit in limitOptions" :key="optLimit"
                             @click="onChangeLimit(optLimit)">
                {{ optLimit }}
            </b-dropdown-item>
        </b-dropdown>
    </div>
</template>

<script>
import translation from "../translation/translate";

export default {
    name: "TableLimit",
    props: {
        limit: {
            type: String,
            default: '10',
        },
    },
    data() {
        return {
            limitOptions: ['10', '20', '50', '100', '1000'],
            translation: translation,
        }
    },
    methods: {
        onChangeLimit(selectedLimit) {
            let vm = this;
            vm.$emit("newLimit", selectedLimit);
            localStorage.setItem('limit', selectedLimit);
        },
    },
}
</script>

<style scoped>

</style>
