<template>
    <b-row>
        <b-col>
            <b-button
                class="my-primary"
                size="sm"
                v-if="page > 1"
                @click="onPrev()"
            >
                <b-icon-chevron-left/>
                <span class="mobile-layout">&nbsp; {{translation.translate("frequentlyRepeated", "peredyduyushaya") }}</span>
            </b-button>
        </b-col>
        <b-col class="text-right">
            <b-button
                class="my-primary"
                size="sm"
                v-if="length >= limit"
                @click="onNext()"
            >
                <span
                    class="mobile-layout">{{ translation.translate("frequentlyRepeated", "sledushaya") }} &nbsp;</span>
                <b-icon-chevron-right/>
            </b-button>
        </b-col>
    </b-row>
</template>

<script>
import translation from "../../translation/translate";

export default {
    name: "Pagination",
    props: ["page", "length", "limit"],
    data() {
        return {
            translation: translation
        }
    },
    methods: {
        onPrev() {
            this.$emit("prevPage");
        },

        onNext() {
            this.$emit("nextPage");
        },
    },
}
</script>

<style scoped>

</style>
